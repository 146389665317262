<template>
    <Dialog :modal="true" :closable="false" v-model:visible="showDialog" :style="{ width: '75vw' }" :draggable="false">
        <div class="container-fluid mt-3 mb-3">
            <div class="row mb-4">
                <div class="col-md-4">
                    <label for="name" class="fw-bold">Name</label>
                    <InputText disabled id="name" class="w-100" type="text" v-model="form.displayName" placeholder="Name" />
                </div>
                <div class="col-md-4">
                    <label for="id" class="fw-bold">Firebase User Id</label>
                    <InputText
                        disabled
                        id="id"
                        class="w-100"
                        type="text"
                        v-model="form.name"
                        placeholder="User name"
                    />
                </div>
                <div class="col-md-4">
                    <label class="fw-bold">Status</label>
                    <div>
                        <RadioButton :disabled="disableStatus" id="active" name="state" :value="UserState.Active" v-model="form.state" />
                        <label for="active" class="fw-bold ms-2 me-5 mt-2">Active</label>
                        <RadioButton
                            :disabled="disableStatus"
                            id="archived"
                            name="state"
                            :value="UserState.Archived"
                            v-model="form.state"
                        />
                        <label for="archived" class="fw-bold ms-2 mt-2">Archived</label>
                    </div>
                </div>
            </div>
            <label for="role" class="fw-bold ps-0">Role</label>
            <Dropdown class="w-100 ps-0" id="role" v-model="form.role" :options="roles" optionLabel="name" optionValue="code" />
        </div>
        <template #footer>
            <hr />
            <Button label="SAVE" :disabled="!userData.id" @click="save()" />
            <Button label="CANCEL" @click="$emit('closeModal')" class="p-button-outlined me-3" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import { UserRole, UserState } from '../../shared/enums';
import { UserService } from '../../apis';
import { toasterTime, messages, userActivities, toasterType } from '../../shared/constants/constants';
import { UserActivity } from '../../shared/utils';
export default {
    name: 'AccessControl',
    components: {
        Dialog,
        Button,
        InputText,
        RadioButton,
        Dropdown
    },
    created() {
        for (const [key, value] of Object.entries(UserRole)) {
            this.roles.push({ name: key, code: value });
        }
    },
    data() {
        return {
            userActivities: userActivities,
            showDialog: true,
            UserState,
            form: {
                displayName: `${ this.userData.name } (${ this.userData.email })`,
                name: this.userData.name,
                state: this.userData.state,
                role: this.userData.role
            },
            roles: []
        };
    },
    props: {
        userData: {
            type: Object,
            required: true,
            default: () => ({ state: UserState.Active })
        },
        disableStatus: Boolean
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async save() {
            const updatedUserData = {
                state: this.form.state,
                role: this.form.role,
                info: {
                    ...UserActivity.getActivity(userActivities.updated),
                    ...UserActivity.getActivity(userActivities.archived, this.form.state)
                }
            };
            try {
                await UserService.update(this.userData.id, updatedUserData);
                this.showToaster(messages.userValidation.userUpdateSuccess, toasterType.success);
                this.$emit('closeModal');
            } catch (err) {
                this.showToaster(messages.userValidation.userUpdateFailed, toasterType.error);
            }
        },
        getRole(roleId) {
            return Object.keys(UserRole).find(id => UserRole[id] === roleId);
        }
    }
};
</script>

<style scoped></style>
