<template>
    <Dialog header="Filter" @hide="$emit('closeFilter')" position="topright" v-model:visible="displayFilter"
    :style="{width: '25.25rem'}" :draggable="false">
        <div class="size14 color-lavender-purple font-weight-500 mb-4" role="button" @click="clearFilters()">CLEAR FILTER</div>
        <div class="text-muted fs-5 font-weight-500 mt-4 mb-3">State</div>
        <div class="field-checkbox margin-top-12 mb-4">
            <Dropdown class="w-100" v-model="selectedState" :options="UserState"
            optionLabel="name" optionValue="code" placeholder="Select a State" />
        </div>
        <div class="text-muted fs-5 font-weight-500">Roles</div>
        <div class="field-checkbox mt-2" v-for="(role,index) in roles" :key="index">
            <Checkbox :value="role.code" v-model="selectedRoles"/>
            <label for="free" class="size14 fw-normal ms-3">{{role.name}}</label>
        </div>
        <Button class="filterText button-size justify-content-center button-color-lavender-purple mt-4 w-100"
        @click="applyfilter()">APPLY</Button>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import { UserRole, UserState } from '../../shared/enums';
import 'primeicons/primeicons.css';
export default {
    name: 'TeamFilter',
    components: {
        Button,
        Dialog,
        Dropdown,
        Checkbox
    },
    created() {
        for (const [key, value] of Object.entries(UserRole)) {
            this.roles.push({ name: key, code: value });
        }
    },
    props: {
        displayFilter: Boolean
    },
    data() {
        return {
            state: [],
            selectedState: null,
            selectedRoles: [],
            roles: [],
            UserState: Object.keys(UserState)
                .map(key => ({ name: String(key), code: UserState[key] }))
        };
    },
    methods: {
        clearFilters() {
            this.selectedState=null;
            this.selectedRoles=[];
            this.$emit('applyFilter', this.selectedState, this.selectedRoles);
        },
        applyfilter() {
            this.$emit('applyFilter', parseInt(this.selectedState), this.selectedRoles);
        }
    }
};
</script>
<style scoped>

</style>
