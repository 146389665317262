<template>
    <div class="container container-width">
        <DataTable
            :paginator="true"
            :rows="rows"
            selectionMode="single"
            :rowsPerPageOptions="rowPerPageOptions"
            :value="users"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            responsiveLayout="scroll"
            sortField="email"
            :sortOrder="sortOrder.Ascending"
        >
            <template #header>
                <Button class="p-button-text" @click="displayFilter = true">
                    <img src="../../assets/filter-icon.png" />
                    <span class="ms-2 color-lavender-purple">Filter</span>
                </Button>
            </template>
            <template #empty> No Users found. </template>
            <template #loading> Loading Users. Please wait. </template>
            <Column field="name" header="Name" sortable class="table-column-title" style="max-width: 15rem">
                <template #body="{ data }">
                    <span
                        class="ms-0 legend-key"
                        :class="data.state === UserState.Active ? 'lavender-purple-background' : 'gray-background'"
                    ></span>
                    <a
                        class="table-first-column"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="data.name"
                        @click.prevent="onRowSelect(data)"
                    >
                        {{ data.name }}
                    </a>
                </template>
            </Column>
            <Column field="email" header="Email" sortable />
            <Column field="roleName" header="Role" sortable />
            <Column field="added" header="Last Login">
                <template #body="{ data }">
                    {{ data.logins?.length ? getLastLoginDate(data.logins) : '' }}
                </template>
            </Column>
            <Column field="userId" header="Firebase User Id" class="table-column-title" style="max-width: 15rem">
                <template #body="{ data }">
                    <span data-toggle="tooltip" data-placement="top" :title="data.name">{{ data.name }}</span>
                </template>
            </Column>
            <template #paginatorstart></template>
        </DataTable>
        <UsersFilter :displayFilter="displayFilter" @applyFilter="applyFilter" @closeFilter="displayFilter = false" />
        <div v-if="this.showModal">
            <AccessControl @closeModal="showModal = false" :disableStatus="disableStatus" :userData="userData" />
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
import { rowPerPageOptions, rows, sortOrder } from '../../shared/constants';
import { UserRole, UserState } from '../../shared/enums';
import UsersFilter from './UsersFilter.vue';
import AccessControl from './AccessControl.vue';
import { UserService } from '../../apis';
import Utility from '../../shared/utility/utility';

export default {
    name: 'Users',
    components: {
        Button,
        Column,
        DataTable,
        AccessControl,
        UsersFilter
    },
    data() {
        return {
            sortOrder,
            users: ref([]),
            showModal: false,
            userData: null,
            disableStatus: false,
            rowPerPageOptions,
            rows,
            UserState,
            displayFilter: false,
            filters: {
                state: { value: '', matchMode: FilterMatchMode.EQUALS },
                role: { value: [], matchMode: FilterMatchMode.IN }
            }
        };
    },
    mounted() {
        this.fetchAllUsers();
    },
    methods: {
        async fetchAllUsers() {
            this.users = await UserService.getSnapshot(this.filters);
            watchEffect(() => {
                if (this.users?.length) {
                    this.users.forEach(u => (u.roleName = Utility.getKeyByValue(UserRole, u.role)));
                }
            });
        },
        getLastLoginDate(logins) {
            const recentLoginDate = Utility.findLast(logins, login => login.what == 0)?.when;
            if (recentLoginDate) {
                const today = moment();
                const loginDate = moment.unix(recentLoginDate.seconds);
                let dateDiff;
                return (
                    ((dateDiff = today.diff(loginDate, 'years')) && dateDiff + ' year(s) ago') ||
                    ((dateDiff = today.diff(loginDate, 'months')) && dateDiff + ' month(s) ago') ||
                    ((dateDiff = today.diff(loginDate, 'days')) && dateDiff + ' day(s) ago') ||
                    ((dateDiff = today.diff(loginDate, 'hours')) && dateDiff + ' hour(s) ago') ||
                    'Within an hour ago'
                );
            }
        },
        onRowSelect(data) {
            this.userData = data;
            this.disableStatus = data.role == 1;
            this.showModal = true;
        },
        applyFilter(state, role) {
            this.filters.state.value = state;
            this.filters.role.value = role;
            this.fetchAllUsers();
            this.displayFilter = false;
        }
    }
};
</script>
